"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createNetSuiteUtils = void 0;
const createNetSuiteUtils = ({ client }) => {
    const fetchData = async (endpoint) => {
        try {
            const data = await client.get(endpoint);
            return data;
        }
        catch (error) {
            console.error("Error fetching data:", error);
            throw error;
        }
    };
    const postData = async (endpoint, data) => {
        try {
            const response = await client.post(endpoint, data);
            return response;
        }
        catch (error) {
            console.error("Error posting data:", error);
            throw error;
        }
    };
    const patchData = async (endpoint, data) => {
        try {
            const response = await client.patch(endpoint, data);
            return response;
        }
        catch (error) {
            console.error("Error patching data:", error);
            throw error;
        }
    };
    const deleteData = async (endpoint) => {
        try {
            const response = await client.delete(endpoint);
            return response;
        }
        catch (error) {
            console.error("Error deleting data:", error);
            throw error;
        }
    };
    const executeSuiteQL = async (query) => {
        try {
            const result = await client.suiteql(query);
            return result;
        }
        catch (error) {
            console.error("Error executing SuiteQL:", error);
            throw error;
        }
    };
    const getCustomer = async (customerId) => {
        return fetchData(`/services/rest/record/v1/customer/${customerId}`);
    };
    const getClass = async (classId) => {
        return fetchData(`/services/rest/record/v1/classification/${classId}`);
    };
    const getSubscriptionPlan = async (planId) => {
        return fetchData(`/services/rest/record/v1/subscriptionPlan/${planId}`);
    };
    const getSubscriptionDetails = async (subscriptionId) => {
        return fetchData(`/services/rest/record/v1/subscription/${subscriptionId}`);
    };
    const getPriceIntervals = async (subscriptionId) => {
        return fetchData(`/services/rest/record/v1/subscription/${subscriptionId}/priceInterval`);
    };
    const fetchPriceIntervals = async (subscriptionId) => {
        try {
            const priceIntervals = await client.get(`/services/rest/record/v1/subscription/${subscriptionId}/priceinterval`);
            return priceIntervals;
        }
        catch (error) {
            console.error("Error fetching subscription lines:", error);
        }
    };
    const getPriceIntervalDetails = async (url) => {
        return fetchData(url);
    };
    const getMaxSubscriptionLineNumber = async (subscriptionId) => {
        return fetchData(`/services/rest/record/v1/subscription/${subscriptionId}/subscriptionLine`);
    };
    const createNewPricePlan = async (payload) => {
        const url = '/services/rest/record/v1/pricePlan';
        const response = client.post(url, payload);
        return response;
    };
    const updateSubscription = async (subscriptionId, payload) => {
        try {
            const url = `/services/rest/record/v1/subscription/${subscriptionId}`;
            const response = await client.patch(url, payload);
            if (response.status === 200) {
                console.log(`Subscription ${subscriptionId} updated successfully.`);
                return true;
            }
            else if (response.status === 204) {
                console.log(`Subscription ${subscriptionId} updated successfully (No Content).`);
                return true;
            }
            else {
                console.log("Failed to update subscription:", response);
                return false;
            }
        }
        catch (error) {
            console.error("Error updating subscription:", error);
            return false;
        }
    };
    const fetchNonSaleInventoryItems = async (itemId) => {
        return await fetchData(`/services/rest/record/v1/noninventorysaleitem/${itemId}`);
    };
    const getCustomerAddresses = async (customerId) => {
        const resp = await fetchData(`/services/rest/record/v1/customer/${customerId}/addressbook`);
        const addresses = await Promise.all(resp.items.map(async (item) => {
            const addressEndpoint = item.links
                .find((link) => link.rel === "self")
                ?.href?.split(".com")?.[1];
            const address = addressEndpoint ? await fetchData(addressEndpoint) : {};
            return address;
        }));
        const addrTexts = addresses.map((addr) => ({
            id: addr.id,
            addrText: addr.addressBookAddress_text,
            defaultBilling: addr.defaultBilling,
            defaultShipping: addr.defaultShipping,
        }));
        return addrTexts;
    };
    const getCustomerSubsidiaries = async (customerId) => {
        const query = `SELECT * FROM customerSubsidiaryRelationship WHERE entity = ${customerId}`;
        const resp = await executeSuiteQL(query);
        return resp.items;
    };
    const addSubsidiaryToCustomer = async (customerId, subsidiaryId) => {
        const payload = {
            entity: { id: customerId },
            subsidiary: { id: subsidiaryId },
        };
        try {
            const response = await client.post("/services/rest/record/v1/customerSubsidiaryRelationship", payload);
            if (response.status === 204) {
                console.log("Subsidiary added to customer successfully.");
            }
            else {
                console.log("Failed to add subsidiary to customer:", response);
            }
        }
        catch (e) {
            const error = e;
            throw new Error("Error adding subsidiary to customer:" + error.message);
        }
    };
    const addAddressToCustomer = async (customerId, payload) => {
        const currentAddresses = await getCustomerAddresses(customerId);
        console.log("Current Addresses:", JSON.stringify(currentAddresses, null, 2));
        const createAddressResp = await client.patch(`/services/rest/record/v1/customer/${customerId}`, {
            addressBook: {
                items: [
                    {
                        addressBookAddress: {
                            ...payload,
                        },
                    },
                ],
            },
        });
        if (createAddressResp.status === 204) {
            console.log("Address added to customer successfully.");
            const newAddresses = await getCustomerAddresses(customerId);
            console.log("New Addresses:", JSON.stringify(newAddresses, null, 2));
            const newAddress = newAddresses.find((addr) => !currentAddresses.some((cAddr) => cAddr.id === addr.id));
            console.log("New Address:", newAddress);
            return newAddress?.id || null;
        }
        console.log("Failed to add address to customer:", createAddressResp);
        return null;
    };
    const createBillingAccount = async (customerId, scheduleId, subsidiaryId, classId, departmentId, currencyId, effectiveDate, invoiceFormId) => {
        const payload = {
            customer: customerId,
            billingschedule: scheduleId,
            subsidiary: subsidiaryId,
            class: classId,
            department: departmentId,
            startdate: effectiveDate,
            currency: {
                id: currencyId,
            },
            invoiceForm: {
                id: invoiceFormId,
            }
        };
        console.log("Payload:", payload);
        try {
            const response = await client.post("/services/rest/record/v1/billingaccount", payload);
            if (response.status === 204) {
                console.log("Billing account created successfully:", response.id);
                return response.id;
            }
            else {
                console.log("Failed to create billing account:", response);
                return null;
            }
        }
        catch (error) {
            console.error("Error creating billing account:", error);
            return null;
        }
    };
    const updateBillingAccountAddress = async (billingAccountId, defaultShippingId, defaultBillingId) => {
        const payload = {
            shipAddressList: defaultShippingId,
            billAddressList: defaultBillingId,
        };
        try {
            const response = await client.patch(`/services/rest/record/v1/billingaccount/${billingAccountId}`, payload);
            if (response.status === 204) {
                console.log("Billing account address updated successfully.");
            }
            else {
                console.log("Failed to update billing account address:", response);
            }
        }
        catch (error) {
            console.error("Error updating billing account address:", error);
        }
    };
    const createPricePlan = async (currencyId, value) => {
        const payload = {
            currency: { id: currencyId },
            pricePlanType: { id: "2" }, // constant for tieredPricing
            priceTiers: {
                items: [
                    {
                        fromVal: 0,
                        pricingOption: { id: "-102" }, // constant for fixed value
                        value: value,
                    },
                ],
            },
        };
        try {
            const response = await client.post("/services/rest/record/v1/pricePlan", payload);
            if (response.status === 204) {
                console.log("Price plan created successfully:", response.id);
                return response.id;
            }
            else {
                console.log("Failed to create price plan:", response);
                return null;
            }
        }
        catch (error) {
            console.error("Error creating price plan:", error);
            return null;
        }
    };
    const createSubscription = async (subName, customerId, billingAccountId, initialTerm, startDate, endDate, enduserId, resellerId, 
    //  salesRepId: string,
    subscriptionPlanId, subscriptionLine, priceInterval, renewalOptions, otherRefNum) => {
        const payload = {
            name: subName,
            customer: {
                id: customerId,
            },
            billingAccount: {
                id: billingAccountId,
            },
            initialTerm: initialTerm,
            startDate: startDate,
            endDate: endDate,
            custrecord_subs_end_user: {
                id: enduserId,
            },
            custrecord_subs_reseller: {
                id: resellerId,
            },
            // salesRep: {
            //   id: salesRepId,
            // },
            subscriptionPlan: {
                id: subscriptionPlanId,
            },
            subscriptionline: {
                items: subscriptionLine.map((line, i) => ({
                    linenumber: line.linenumber,
                    item: line.item,
                    revrecoption: line.revrecoption,
                    billingmode: line.billingmode,
                    subscriptionlinetype: line.subscriptionlinetype,
                    isIncluded: line.isIncluded,
                })),
            },
            priceinterval: {
                items: priceInterval.map((interval, i) => ({
                    linenumber: interval.linenumber,
                    subscriptionplanlinenumber: interval.subscriptionplanlinenumber,
                    item: interval.item,
                    startDate: interval.startDate,
                    endDate: interval.endDate,
                    chargeFrequency: interval.chargeFrequency,
                    repeatEvery: interval.repeatEvery,
                    quantity: interval.quantity,
                    pricePlan: interval.pricePlan,
                })),
            },
            autoRenewal: renewalOptions.autoRenewal,
            defaultRenewalMethod: {
                id: renewalOptions.defaultRenewalMethod,
            },
            defaultRenewalTerm: {
                id: renewalOptions.defaultRenewalTerm,
            },
            defaultRenewalPlan: {
                id: renewalOptions.defaultRenewalPlan,
            },
            defaultRenewalTranType: "",
            advanceRenewalPeriodNumber: renewalOptions.advanceRenewalPeriodNumber,
            advanceRenewalPeriodUnit: {
                id: renewalOptions.advanceRenewalPeriodUnit,
            },
            custrecord_customer_po: otherRefNum,
        };
        console.log("Payload:", payload);
        console.log("JSON Payload:", JSON.stringify(payload, null, 2));
        try {
            const response = await client.post("/services/rest/record/v1/subscription", payload);
            if (response.status === 204) {
                console.log("Subscription created successfully:", response.id);
                return response.id;
            }
            else {
                console.log("Failed to create subscription:", response);
                return null;
            }
        }
        catch (error) {
            console.error("Error creating subscription:", error);
            return null;
        }
    };
    const turnOffAutoRenewal = async (subscriptionId) => {
        const payload = {
            autoRenewal: false,
        };
        try {
            const response = await client.patch(`/services/rest/record/v1/subscription/${subscriptionId}`, payload);
            if (response.status === 204) {
                console.log("Auto-renewal turned off successfully.");
            }
            else {
                console.log("Failed to turn off auto-renewal:", response);
            }
        }
        catch (error) {
            console.error("Error turning off auto-renewal:", error);
        }
    };
    const getSubscriptionPlanLines = async (subscriptionPlanId) => {
        try {
            const response = (await fetchData(`/services/rest/record/v1/subscriptionPlan/${subscriptionPlanId}/member`));
            console.log("Subscription Plan Lines Response:", JSON.stringify(response, null, 2));
            const lines = (await Promise.all(response.items.map(async (item) => {
                const itemUrl = item.links
                    .find((link) => link.rel === "self")
                    ?.href?.split(".com")[1] || "";
                const itemData = await fetchData(itemUrl);
                return itemData;
            })));
            console.log("Subscription Plan Lines:", JSON.stringify(lines, null, 2));
            return lines;
        }
        catch (error) {
            console.error("Error fetching subscription plan items:", error);
            return null;
        }
    };
    const getSubscriptionLines = async (subscriptionId) => {
        try {
            const lineLinks = await fetchData(`/services/rest/record/v1/subscription/${subscriptionId}/subscriptionline`);
            const lines = await Promise.all(lineLinks.items.map(async (item) => {
                console.log(item.links
                    .find((link) => link.rel === "self")
                    ?.href?.split(".com")[1] || "");
                const line = await fetchData(item.links
                    .find((link) => link.rel === "self")
                    ?.href?.split(".com")[1] || "");
                return line;
            }));
            return lines;
        }
        catch (error) {
            console.error("Error fetching subscription lines:", error);
        }
    };
    const getSubscriptionLinesNew = async (subscriptionId) => {
        try {
            const lineLinks = await fetchData(`/services/rest/record/v1/subscription/${subscriptionId}/subscriptionline`);
            const lines = await Promise.allSettled(lineLinks.items.map(async (item) => {
                try {
                    const itemUrl = item.links.find((link) => link.rel === "self")?.href?.split(".com")[1] || "";
                    const itemId = parseInt(item.links[0].href.split("/").pop() || "");
                    const line = await fetchData(itemUrl);
                    if (line?.status?.refName === "Active") {
                        console.log(`Item ${itemId} is active`);
                        return itemId;
                    }
                    return null;
                }
                catch (error) {
                    console.error(`Failed to fetch line for item ${item}:`, error);
                    return null; // or handle the error as needed
                }
            }));
            // Handle all results and discard null values (which represent failed requests)
            const fulfilledLines = lines
                .map((result) => result.status === 'fulfilled' ? result.value : null) // Map to value or null
                .filter((line) => line !== null) // Filter out null values
                .map((line) => line); // Cast to number
            console.log("Fulfilled Lines:", fulfilledLines);
            return fulfilledLines;
        }
        catch (error) {
            console.error("Error fetching subscription lines:", error);
        }
    };
    const updateSubscriptionLineStatus = async (subscriptionId, lineNumber, statusId) => {
        const endpoint = `/services/rest/record/v1/subscription/${subscriptionId}/subscriptionline/${lineNumber}`;
        const payload = { status: { id: statusId } };
        try {
            const response = await client.patch(endpoint, payload);
            if (response.status === 204) {
                console.log(`Subscription line ${lineNumber} updated to status ${statusId} successfully.`);
            }
            else {
                console.log(`Failed to update subscription line ${lineNumber} to status ${statusId}:`, response);
            }
        }
        catch (error) {
            console.error(`Error updating subscription line ${lineNumber} to status ${statusId}:`, error);
        }
    };
    const createContractualDocument = async (subscriptionId, documentLink) => {
        const payload = {
            custrecord_sub_doc: { id: subscriptionId },
            name: documentLink,
        };
        try {
            const response = await client.post("/services/rest/record/v1/customrecord697", payload);
            if (response.status === 204) {
                console.log("Contractual document created successfully.");
                return response.id;
            }
            else {
                console.log("Failed to create contractual document:", response);
                return null;
            }
        }
        catch (error) {
            console.error("Error creating contractual document:", error);
            return null;
        }
    };
    const linkContractualDocument = async (subscriptionId, documentId) => {
        const payload = {
            custrecord_contract_docs: { id: documentId },
        };
        try {
            const response = await client.patch(`/services/rest/record/v1/subscription/${subscriptionId}`, payload);
            if (response.status === 204) {
                console.log("Contractual document linked successfully.");
            }
            else {
                console.log("Failed to link contractual document:", response);
            }
        }
        catch (error) {
            console.error("Error linking contractual document:", error);
        }
    };
    const activateSubscription = async (customerId, billingAccountId, subscriptionId, itemNumbers, startDate, refNumber) => {
        const payload = {
            action: { id: "ACTIVATE" },
            approvalStatus: { id: "APPROVED" },
            modificationType: { id: "NEW_CHURN" },
            customer: { id: customerId },
            billingAccount: { id: billingAccountId },
            subscription: { id: subscriptionId },
            subline: {
                items: itemNumbers.map((itemNumber) => ({
                    apply: true,
                    sequence: itemNumber,
                })),
            },
            effectiveDate: startDate,
            custrecord_st_change_reason: {
                id: 1,
            },
            memo: refNumber,
        };
        try {
            const response = await client.post("/services/rest/record/v1/subscriptionChangeOrder", payload);
            if (response.status === 204) {
                console.log("Subscription activated successfully.");
            }
            else {
                console.log("Failed to activate subscription:", response);
            }
        }
        catch (error) {
            console.error("Error activating subscription:", error);
        }
    };
    const terminateSubscription = async (customerId, billingAccountId, subscriptionId, itemNumbers, endDate) => {
        const endDateF = new Date(endDate);
        console.log(`End Date: ${endDateF.toISOString().split("T")[0]}`);
        const payload = {
            action: { id: "TERMINATE" },
            approvalStatus: { id: "APPROVED" },
            customer: { id: customerId },
            billingAccount: { id: billingAccountId },
            subscription: { id: subscriptionId },
            subline: {
                items: itemNumbers.map((itemNumber) => ({
                    apply: true,
                    sequence: itemNumber,
                })),
            },
            effectiveDate: endDateF.toISOString().split("T")[0],
            custrecord_st_change_reason: {
                id: 1,
            },
            requestOffCycleInvoice: true,
            terminateAtStartOfDay: true
        };
        try {
            const response = await client.post(`/services/rest/record/v1/subscriptionChangeOrder`, payload);
            if (response.status === 204) {
                console.log("Subscription terminated successfully.");
                return true;
            }
            else {
                console.log("Failed to terminate subscription:", response);
                return false;
            }
        }
        catch (error) {
            console.error("Error terminating subscription:", error);
            return false;
        }
    };
    const createSubscriptionChangeOrder = async (customerId, billingAccountId, subscriptionId, itemNumbers, startDate, negativeValue, refNumber) => {
        const payload = {
            action: { id: "ACTIVATE" },
            approvalStatus: { id: "APPROVED" },
            modificationType: { id: "NEW_CHURN" },
            customer: { id: customerId },
            billingAccount: { id: billingAccountId },
            subscription: { id: subscriptionId },
            subline: {
                items: itemNumbers.map((itemNumber) => ({
                    apply: true,
                    sequence: itemNumber,
                })),
            },
            effectiveDate: startDate,
            custrecord_st_change_reason: {
                id: 1,
            },
            totalintervalvalue: negativeValue,
            memo: refNumber,
        };
        try {
            const response = await client.post("/services/rest/record/v1/subscriptionChangeOrder", payload);
            if (response.status === 204 || response.status === 200 || response.status === 201) {
                console.log("Subscription activated successfully.");
                return true;
            }
            else {
                console.log("Failed to activate subscription:", response);
                return false;
            }
        }
        catch (error) {
            console.error("Error activating subscription:", error);
            return false;
        }
    };
    const createInvoice = async (customerId, billingAccountId, startDate, endDate, asOfDate, customFormId, memo, paymentTerms, otherRefNum) => {
        try {
            const payload = {
                entity: { id: customerId },
                custbody_end_user_new: { id: customerId },
                billingAccount: { id: billingAccountId },
                whichChargesToAdd: {
                    id: "AS_OF_DATE",
                },
                asOfDate,
                tranDate: new Date().toISOString().slice(0, 10), // today's date
                startDate,
                endDate,
                customForm: {
                    id: customFormId,
                },
                terms: {
                    id: paymentTerms,
                },
                memo: memo,
                otherRefNum,
                taxdetailsoverride: client.isProd ? undefined : true,
            };
            const response = await client.post("/services/rest/record/v1/invoice", payload);
            if (response.status === 204) {
                console.log("Invoice created successfully:", response.id);
                return response.id;
            }
            else {
                console.log("Failed to create invoice:", response);
                return null;
            }
        }
        catch (error) {
            console.error("Error creating invoice:", error);
            return null;
        }
    };
    const updateInvoiceMetadata = async (invoiceId, startDate, endDate, otherRefNum) => {
        try {
            const response = await client.patch(`/services/rest/record/v1/invoice/${invoiceId}`, {
                startDate,
                endDate,
                otherRefNum,
            });
            if (response.status === 204) {
                console.log("Invoice metadata updated successfully.");
            }
            else {
                console.log("Failed to update invoice metadata:", response);
            }
        }
        catch (error) {
            console.error("Error updating invoice metadata:", error);
        }
    };
    const updateInvoiceDescription = async (invoiceId, description) => {
        const payload = {
            description,
        };
        try {
            // must be first item on description
            const response = await client.patch(`/services/rest/record/v1/invoice/${invoiceId}/item/1`, payload);
            if (response.status === 204) {
                console.log("Invoice description updated successfully.");
            }
            else {
                console.log("Failed to update invoice description:", response);
            }
        }
        catch (error) {
            console.error("Error updating invoice description:", error);
        }
    };
    const updateInvoiceSubscriptionPlan = async (invoiceId, subscriptionPlanId) => {
        const payload = {
            custcol_inv_subs_plan: { id: subscriptionPlanId },
        };
        try {
            const response = await client.patch(`/services/rest/record/v1/invoice/${invoiceId}/item/1`, payload);
            if (response.status === 204) {
                console.log("Invoice subscription plan updated successfully.");
            }
            else {
                console.log("Failed to update invoice subscription plan:", response);
            }
        }
        catch (error) {
            console.error("Error updating invoice subscription plan:", error);
        }
    };
    const updateInvoiceItemDates = async (invoiceId, startDate, endDate) => {
        const payload = {
            custcol_swe_contract_end_date: endDate,
            custcol_swe_contract_start_date: startDate,
        };
        try {
            const response = await fetchData(`/services/rest/record/v1/invoice/${invoiceId}/item`);
            const itemLinks = response.items.map((item) => item.links.find((link) => link.rel === "self")?.href);
            const itemIds = itemLinks.map((link) => link.split(".com")[1]);
            const responses = [];
            for (const itemId of itemIds) {
                // Throttle by 1 second
                await new Promise((resolve) => setTimeout(resolve, 1000));
                const itemResponse = await client.patch(itemId, payload);
                responses.push(itemResponse);
            }
            if (responses.every((resp) => resp.status === 204)) {
                console.log("Invoice item dates updated successfully.");
            }
            else {
                console.log("Failed to update invoice item dates:", response);
            }
        }
        catch (error) {
            console.error("Error updating invoice item dates:", error);
        }
    };
    const uploadFileAndAttachToRecord = async (restletId, deployId, folderId, file, record) => {
        try {
            const response = await client.restlet(restletId, deployId, "POST", {
                folderId,
                fileData: file.fileData,
                fileName: file.fileName,
                fileType: file.fileType,
                recordType: record.recordType,
                recordId: record.recordId,
            });
            if (response.status === 200) {
                const data = await response.json();
                if (data.error) {
                    console.log("Failed to upload file:", data.error);
                    return null;
                }
            }
        }
        catch (error) {
            console.error("Error uploading file:", error);
            return null;
        }
    };
    const sendInvoice = async (restletId, deployId, invoiceId, recipientEmail, additionalRecipients, bcc, emailTemplateId) => {
        const payload = {
            invoiceId,
            recipientEmail,
            additionalRecipients,
            bcc,
            emailTemplateId,
        };
        try {
            const response = await client.restlet(restletId, deployId, "POST", payload);
            if (response.status === 200) {
                const data = await response.json();
                console.log("Response:", data);
                if (!data.success) {
                    console.log("Failed to send invoice:", data.error);
                }
            }
        }
        catch (error) {
            console.error("Error sending invoice:", error);
        }
    };
    const getCustomerPreferences = async (customerId) => {
        return fetchData(`/services/rest/record/v1/customer/${customerId}`);
    };
    const updateCustomerPreferences = async (customerId, payload) => {
        const response = await client.patch(`/services/rest/record/v1/customer/${customerId}`, payload);
        if (response.status >= 200 && response.status < 300) {
            console.log(`Customer ${customerId} preferences updated successfully.`);
            return true;
        }
        console.log(`Failed to update customer ${customerId} preferences:`, response);
        return false;
    };
    const createCreditMemo = async (payload) => {
        const response = await client.post(`/services/rest/record/v1/creditMemo`, payload);
        if (response.status === 204) {
            console.log("Credit Memo created successfully.");
            const creditmemo_id = response?.id;
            return creditmemo_id;
        }
        else {
            console.log("Failed to create credit memo:", response);
            return null;
        }
    };
    const updateCreditMemoTranId = async (creditMemoId, payload) => {
        const response = await client.patch(`/services/rest/record/v1/creditMemo/${creditMemoId}`, payload);
        if (response.status === 204) {
            console.log(`Credit Memo ${creditMemoId} updated successfully.`);
        }
        else {
            console.log(`Failed to update credit memo ${creditMemoId}:`, response);
            throw new Error(`Failed to update credit memo ${creditMemoId}: ${response}`);
        }
    };
    const addCustomerSusidiaryRelationship = async (payload) => {
        return await client.post(`/services/rest/record/v1/customerSubsidiaryRelationship`, payload);
    };
    const createNewSubscription = async (payload) => {
        const response = await client.post(`/services/rest/record/v1/subscription`, payload);
        if (response.status === 204) {
            console.log("New Subscription created successfully.");
            return response.id;
        }
        else if (response.status === 200 || response.status === 201) {
            console.log("New Subscription created successfully.");
            const id = response.headers.get("Location")?.split("/").pop();
            return id;
        }
        else {
            return null;
        }
    };
    const updatePricePlanValue = async (pricePlanId, value) => {
        const payload = {
            value: value
        };
        const response = await client.patch(`/services/rest/record/v1/pricePlan/${pricePlanId}/priceTiers/0`, payload);
        if (response.status === 204) {
            console.log(`Price Plan ${pricePlanId} updated successfully.`);
        }
        else {
            console.log(`Failed to update price plan ${pricePlanId}:`, response);
            throw new Error(`Failed to update price plan ${pricePlanId}: ${response}`);
        }
    };
    const updateLineItemStatus = async (newSubscriptionId, lineNumber, status) => {
        const payload = {
            status: {
                refName: status
            }
        };
        const response = await client.patch(`/services/rest/record/v1/subscription/${newSubscriptionId}/subscriptionline/${lineNumber}`, payload);
        if (response.status === 204) {
            console.log(`Line Item ${lineNumber} updated successfully.`);
        }
        else {
            console.log(`Failed to update line item ${lineNumber}:`, response);
            throw new Error(`Failed to update line item ${lineNumber}: ${response}`);
        }
    };
    return {
        fetchData,
        postData,
        executeSuiteQL,
        getCustomer,
        getCustomerAddresses,
        getClass,
        getSubscriptionPlan,
        getCustomerSubsidiaries,
        addSubsidiaryToCustomer,
        addAddressToCustomer,
        createBillingAccount,
        createPricePlan,
        createSubscription,
        getSubscriptionPlanLines,
        getSubscriptionLines,
        getSubscriptionDetails,
        updateSubscriptionLineStatus,
        updateBillingAccountAddress,
        createContractualDocument,
        linkContractualDocument,
        activateSubscription,
        createInvoice,
        updateInvoiceMetadata,
        updateInvoiceSubscriptionPlan,
        updateInvoiceDescription,
        turnOffAutoRenewal,
        updateInvoiceItemDates,
        uploadFileAndAttachToRecord,
        sendInvoice,
        getPriceIntervals,
        getPriceIntervalDetails,
        createNewPricePlan,
        getMaxSubscriptionLineNumber,
        fetchPriceIntervals,
        updateSubscription,
        fetchNonSaleInventoryItems,
        createSubscriptionChangeOrder,
        terminateSubscription,
        getCustomerPreferences,
        updateCustomerPreferences,
        createCreditMemo,
        updateCreditMemoTranId,
        getSubscriptionLinesNew,
        addCustomerSusidiaryRelationship,
        createNewSubscription,
        updatePricePlanValue,
        updateLineItemStatus,
        patchData,
        deleteData
    };
};
exports.createNetSuiteUtils = createNetSuiteUtils;
