import React, { createContext, useContext, useState, ReactNode } from "react";
import { SubscriptionTransferProps } from "models/subscription-transfer";
import dayjs from "dayjs";

interface SubTransferFormContextProps {
  subTransferFormData: SubscriptionTransferProps;
  setSubTransferFormData: React.Dispatch<React.SetStateAction<SubscriptionTransferProps>>;
}

const initialFormState: SubscriptionTransferProps = {
    refNumber: "WILL-BE-ADDED-LATER",
    subscriptionId: "",
    newSubsidiaryId: "",
    draftSubscription: "",
    startDate: dayjs(),
    endDate: dayjs(),
};

const SubTransferFormContext = createContext<SubTransferFormContextProps | undefined>(
  undefined,
);

export const useSubTransferFormContext = () => {
  const context = useContext(SubTransferFormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};

export const SubTransferFormProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [subTransferFormData, setSubTransferFormData] = useState(initialFormState);

  return (
    <SubTransferFormContext.Provider
      value={{
        subTransferFormData,
        setSubTransferFormData,
      }}
    >
      {children}
    </SubTransferFormContext.Provider>
  );
};
