import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ServiceList from "./components/general/ServiceList";
import InvoiceForm from "./components/invoices/InvoiceForm";
import Invoice from "./components/invoices/Invoice";
import { InvoiceFormProvider } from "./contexts/InvoiceFormContext";
import { useAuth } from "./contexts/AuthContext";
import AuthPage from "./components/general/AuthPage";
import UserManagementPage from "./components/users/UserManagementPage";
import Invoices from "./components/invoices/Invoices";
import SubscriptionQCList from "./components/subscription-qc/SubscriptionQCs"; // Import the SubscriptionQC list page
import SubscriptionQC from "./components/subscription-qc/SubscriptionQC"; // Import the SubscriptionQC detail page
import RecurringInvoiceQC from "./components/recurring-invoice-qc/RecurringInvoiceQC";
import RecurringInvoiceQCs from "./components/recurring-invoice-qc/RecurringInvoiceQCs";
import ExceptionInvoice from "./components/exception-invoices/ExceptionInvoice";
import ExceptionInvoices from "./components/exception-invoices/ExceptionInvoices";
import ExceptionInvoicesForm from "./components/exception-invoices/ExceptionInvoicesForm";
import PoNumberReissueForm from "./components/po-number-reissue/PoReissueForm";
import PoReissue from "./components/po-number-reissue/PoReissue";
import PoReissues from "./components/po-number-reissue/PoReissues";
import SubscriptionTransferForm from "./components/subscription-transfer/subscriptionTransferForm";
import SubscriptionTransfers from "./components/subscription-transfer/subscriptionTransfers";
import { SubTransferFormProvider } from "./contexts/SubscriptionTransferFormContext";
import SubscriptionTransfer from "./components/subscription-transfer/subscriptionTransfer";

const AppRouter: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      {!isAuthenticated ? (
        <Routes>
          <Route path="/" element={<AuthPage />} />
          <Route path="/*" element={<AuthPage />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<ServiceList />} />

          {/* User Management Routes */}
          <Route path="/users" element={<UserManagementPage />} />

          {/* Invoice Routes */}
          <Route path="/invoices" element={<Invoices />} />
          <Route
            path="/invoices/ps-new-biz-form"
            element={
              <InvoiceFormProvider>
                <InvoiceForm />
              </InvoiceFormProvider>
            }
          />
          <Route path="/invoices/:id" element={<Invoice />} />

          {/* Subscription Transfer Routes */}
          <Route
            path="/subscription-transfers"
            element={<SubscriptionTransfers />}
          />
          <Route
            path="/subscription-transfers/ps-new-biz-form"
            element={
              <SubTransferFormProvider>
                <SubscriptionTransferForm />
              </SubTransferFormProvider>
            }
          />
          <Route path="/subscription-transfers/:id" element={<SubscriptionTransfer />} />
          
          {/* Subscription QC Routes */}
          <Route path="/subscription-qc" element={<SubscriptionQCList />} />
          <Route path="/subscription-qc/:id" element={<SubscriptionQC />} />

          {/* Recurring Invoice QC Routes */}
          <Route
            path="/recurring-invoice-qc"
            element={<RecurringInvoiceQCs />}
          />
          <Route
            path="/recurring-invoice-qc/:id"
            element={<RecurringInvoiceQC />}
          />

          {/* Invoice Exception Routes */}
          <Route path="/exception-invoices" element={<ExceptionInvoices />} />
          <Route
            path="/exception-invoices/:id"
            element={<ExceptionInvoice />}
          />
          <Route
            path="/invoice-exceptions/form"
            element={<ExceptionInvoicesForm />}
          />

          {/* PO Inv Reissue Routes */}
          <Route path="/po-reissues" element={<PoReissues />} />
          <Route path="/po-reissues/form" element={<PoNumberReissueForm />} />
          <Route path="/po-reissues/:id" element={<PoReissue />} />
        </Routes>
      )}
    </Router>
  );
};

export default AppRouter;
