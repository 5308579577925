"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Permission = exports.UserRole = void 0;
var UserRole;
(function (UserRole) {
    UserRole["ADMIN"] = "admin";
    UserRole["SUPERVISOR"] = "supervisor";
    UserRole["REQUESTER"] = "requester";
})(UserRole || (exports.UserRole = UserRole = {}));
var Permission;
(function (Permission) {
    Permission["Invoice"] = "invoice";
    Permission["SubscriptionTransfer"] = "subscription-transfer";
})(Permission || (exports.Permission = Permission = {}));
