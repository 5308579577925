import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Box, IconButton, Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {SubscriptionTransferRequestService } from '../../services/subscriptionTransferService';
import { useSubTransferFormContext } from "../../contexts/SubscriptionTransferFormContext";

const SubscriptionTransferForm: React.FC = () => {
  const {subTransferFormData, setSubTransferFormData} = useSubTransferFormContext();
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for submit button
  const subscriptionTransferService = new SubscriptionTransferRequestService();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the button
    try {
      console.log("Creating subscription transfer with data:", subTransferFormData);
      const params = subTransferFormData;
      const subTransferRequest = await subscriptionTransferService.createSubscriptionTransferRequest(params);
      console.log("Subscription Transfer Request created:", subTransferRequest);
      navigate(`/subscription-transfers/subtransfer-${subTransferRequest.requestId}`);
    } catch (error) {
      console.error("Error creating subscription transfer request:", error);
    }
    setIsSubmitting(false); // Enable the button
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container sx={{ pt: 4 }}>
        <Box display="flex" alignItems="center" mb={3}>
          <IconButton onClick={() => navigate("/")} size="small">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" ml={2}>
            Subscription Transfer
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Subscription ID"
              name="subscriptionId"
              value={subTransferFormData.subscriptionId}
              onChange={(e) => setSubTransferFormData({...subTransferFormData, subscriptionId: e.target.value})}
              fullWidth
              margin="normal"
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>New Subsidiary</InputLabel>
              <Select
                value={subTransferFormData.newSubsidiaryId || ''}
                onChange={(e) => setSubTransferFormData({...subTransferFormData, newSubsidiaryId: e.target.value})}
                label="New Subsidiary"
              >
                <MenuItem value="433">2HR Learning AZ, Inc.</MenuItem>
                <MenuItem value="397">2HR Learning, Inc.</MenuItem>
                <MenuItem value="25">@Hand Australia Pty Ltd</MenuItem>
                <MenuItem value="315">@Hand Software Corporation (Elimination)</MenuItem>
                <MenuItem value="11">@Hand Software, LLC</MenuItem>
                <MenuItem value="245">AU Branch of S.L.I. Systems, Inc. -Old</MenuItem>
                <MenuItem value="189">AVOLIN SOFTWARE INDIA PRIVATE LIMITED</MenuItem>
                <MenuItem value="16">Accept Software Corporation</MenuItem>
                <MenuItem value="334">Accuris Holdings Limited (Ireland)</MenuItem>
                <MenuItem value="341">Accuris Holdings Limited (Ireland) (Elimination)</MenuItem>
                <MenuItem value="335">Accuris Interception Limited (Ireland)</MenuItem>
                <MenuItem value="336">Accuris Networks Limited (Ireland)</MenuItem>
                <MenuItem value="340">Accuris Networks Limited (Ireland) (Elimination)</MenuItem>
                <MenuItem value="339">Accuris Networks Malaysisa Sdn. Bhd. (Malaysia)</MenuItem>
                <MenuItem value="338">Accuris Networks, Inc. (DE)</MenuItem>
                <MenuItem value="337">Accuris Technologies Limited</MenuItem>
                <MenuItem value="241">Aclate France SARL</MenuItem>
                <MenuItem value="242">Aclate Ontario Inc.</MenuItem>
                <MenuItem value="292">Aclate Ontorio Inc. (Elimination)</MenuItem>
                <MenuItem value="152">Aclate, Inc.</MenuItem>
                <MenuItem value="291">Aclate, Inc. (Elimination)</MenuItem>
                <MenuItem value="14">Acorn Investment Corp.</MenuItem>
                <MenuItem value="264">Acorn Investment Corp. (Elimination)</MenuItem>
                <MenuItem value="256">Acorn Performance Group, Inc. (Elimination)</MenuItem>
                <MenuItem value="13">Acorn Performance Group, LLC</MenuItem>
                <MenuItem value="390">Adara Enterprises Corp.</MenuItem>
                <MenuItem value="65">Agentek, Inc.</MenuItem>
                <MenuItem value="434">Alpha 33155, LLC</MenuItem>
                <MenuItem value="429">Alpine Lakes Software SARL</MenuItem>
                <MenuItem value="428">Alpine Lakes Software Sarl - Old</MenuItem>
                <MenuItem value="430">Alpine Lakes Software Sarl Old</MenuItem>
                <MenuItem value="17">Artemis International Corporation Limited</MenuItem>
                <MenuItem value="265">Artemis International Corporation Limited (Elimination)</MenuItem>
                <MenuItem value="18">Artemis International Investment Holdings (Hong Kong) Limited</MenuItem>
                <MenuItem value="316">Artemis International Investment Holdings (Hong Kong) Limited (Elimination)</MenuItem>
                <MenuItem value="23">Artemis International KK</MenuItem>
                <MenuItem value="257">Artemis International Solutions Corporation (Elimination)</MenuItem>
                <MenuItem value="15">Artemis International Solutions, LLC</MenuItem>
                <MenuItem value="90">Aurea Energy Solutions, Inc.</MenuItem>
                <MenuItem value="221">Aurea Global Sales, LLC</MenuItem>
                <MenuItem value="92">Aurea SAS (France)</MenuItem>
                <MenuItem value="91">Aurea Software FZ-LLC (Dubai)</MenuItem>
                <MenuItem value="170">Aurea Software FZ-LLC (Elimination)</MenuItem>
                <MenuItem value="93">Aurea Software GmbH (Vienna/Austria)</MenuItem>
                <MenuItem value="176">Aurea Software GmbH (Vienna/Austria) (Elimination)</MenuItem>
                <MenuItem value="220">Aurea Software Holdings, LLC</MenuItem>
                <MenuItem value="252">Aurea Software Holdings, LLC (Elimination)</MenuItem>
                <MenuItem value="222">Aurea Software Limited (Malta)</MenuItem>
                <MenuItem value="171">Aurea Software, Inc. (Elimination)</MenuItem>
                <MenuItem value="89">Aurea Software, LLC</MenuItem>
                <MenuItem value="28">Auto-Trol Investment Corporation</MenuItem>
                <MenuItem value="262">Auto-Trol Technology Corporation (Elimination)</MenuItem>
                <MenuItem value="180">Avolin Canada Corporation</MenuItem>
                <MenuItem value="361">Avolin Canada Limited (Elimination)</MenuItem>
                <MenuItem value="193">Avolin Canada Ltd</MenuItem>
                <MenuItem value="289">Avolin Canada Ltd (Elimination)</MenuItem>
                <MenuItem value="179">Avolin Inc (old)</MenuItem>
                <MenuItem value="286">Avolin Inc. (Elimination)</MenuItem>
                <MenuItem value="194">Avolin Japan Co. Ltd</MenuItem>
                <MenuItem value="181">Avolin LLC</MenuItem>
                <MenuItem value="184">Avolin Sdn Bhd</MenuItem>
                <MenuItem value="197">Avolin Software Technology (Shenzhen) Co., Ltd.</MenuItem>
                <MenuItem value="192">Avolin UK Limited</MenuItem>
                <MenuItem value="287">Avolin, LLC (Elimination)</MenuItem>
                <MenuItem value="417">BH6 Holdings, LLC</MenuItem>
                <MenuItem value="358">BVSN, LLC</MenuItem>
                <MenuItem value="391">BVSN, LLC (Elimination)</MenuItem>
                <MenuItem value="201">Beckon, Inc.</MenuItem>
                <MenuItem value="202">Bespeak, Inc.</MenuItem>
                <MenuItem value="105">Bizness Apps, LLC</MenuItem>
                <MenuItem value="377">BroadVision (UK) Ltd</MenuItem>
                <MenuItem value="373">BroadVision France S.A.</MenuItem>
                <MenuItem value="380">BroadVision Japan K. K.</MenuItem>
                <MenuItem value="381">Broadvision (Delaware) LLC</MenuItem>
                <MenuItem value="362">Broadvision Barbados Ltd</MenuItem>
                <MenuItem value="363">Broadvision Deutschland GmbH i.L.</MenuItem>
                <MenuItem value="365">Broadvision Iberica SA</MenuItem>
                <MenuItem value="379">Broadvision Inc, Taiwan Branch</MenuItem>
                <MenuItem value="374">Broadvision Italia srl</MenuItem>
                <MenuItem value="372">Broadvision Professional Services SA</MenuItem>
                <MenuItem value="376">Broadvision Systems India Pvt Ltd</MenuItem>
                <MenuItem value="384">Callstream Group Limited</MenuItem>
                <MenuItem value="385">Callstream Limited</MenuItem>
                <MenuItem value="386">City Numbers Limited</MenuItem>
                <MenuItem value="426">Cloudfix Holdings, LLC</MenuItem>
                <MenuItem value="383">Codium Software LLC</MenuItem>
                <MenuItem value="30">Compressus Investment Corp</MenuItem>
                <MenuItem value="29">Compressus, Inc.</MenuItem>
                <MenuItem value="187">Computron Software Australia Pty Ltd</MenuItem>
                <MenuItem value="188">Computron Software Ltd</MenuItem>
                <MenuItem value="186">Computron Software, LLC</MenuItem>
                <MenuItem value="288">Computron Software, LLC (Elimination)</MenuItem>
                <MenuItem value="224">Conarc, Inc.</MenuItem>
                <MenuItem value="183">Coretrac, Inc.</MenuItem>
                <MenuItem value="62">Crossover Market LLC</MenuItem>
                <MenuItem value="60">Crossover Markets DMCC (Dubai)</MenuItem>
                <MenuItem value="75">Crossover Markets, Inc.</MenuItem>
                <MenuItem value="226">Cyberlink ASP Technology, Inc.</MenuItem>
                <MenuItem value="268">DNN Corp. (Elimination)</MenuItem>
                <MenuItem value="22">DNN, LLC</MenuItem>
                <MenuItem value="1">DevFactory FZ LLC</MenuItem>
                <MenuItem value="274">DevFactory FZ LLC (Elimination)</MenuItem>
                <MenuItem value="59">DevFactory FZ-LLC (Dubai)</MenuItem>
                <MenuItem value="61">DevFactory Innovations FZ - LLC</MenuItem>
                <MenuItem value="416">Diamond Age Learning, Inc.</MenuItem>
                <MenuItem value="113">Dynamic Owl Consulting, Inc.</MenuItem>
                <MenuItem value="21">ESW Capital LLC (DE)</MenuItem>
                <MenuItem value="251">ESW Capital LLC (DE) (Elimination)</MenuItem>
                <MenuItem value="177">ESW Holdings (Elimination)</MenuItem>
                <MenuItem value="272">ESW Investment Corp. (Elimination)</MenuItem>
                <MenuItem value="58">ESW Investment, LLC</MenuItem>
                <MenuItem value="404">ESW Operations, LLC</MenuItem>
                <MenuItem value="393">ESW Technologies FZ-LLC (Elimination)</MenuItem>
                <MenuItem value="37">Engine Yard Enterprises, LLC</MenuItem>
                <MenuItem value="116">Ensequence, Inc.</MenuItem>
                <MenuItem value="420">Esports Academy, LLC</MenuItem>
                <MenuItem value="127">Exinda Canada ULC</MenuItem>
                <MenuItem value="125">Exinda Inc</MenuItem>
                <MenuItem value="173">Exinda Inc (Elimination)</MenuItem>
                <MenuItem value="126">Exinda Ireland Ltd</MenuItem>
                <MenuItem value="147">Exinda Networks Pty Ltd</MenuItem>
                <MenuItem value="346">Exinda Networks Pty Ltd - new</MenuItem>
                <MenuItem value="74">First Rain Software Centre Private Limited</MenuItem>
                <MenuItem value="411">Future of Education HoldCo, LLC</MenuItem>
                <MenuItem value="412">Future of Education, LLC</MenuItem>
                <MenuItem value="51">G-Dev Cyprus Limited (Cyprus)</MenuItem>
                <MenuItem value="273">G-Dev Cyprus Limited (Cyprus) (Elimination)</MenuItem>
                <MenuItem value="50">G-Dev Enterprises LLC</MenuItem>
                <MenuItem value="269">G-Dev Enterprises LLC (Elimination)</MenuItem>
                <MenuItem value="137">GFI Holding Malta Limited</MenuItem>
                <MenuItem value="175">GFI Holding Malta Limited (Elimination)</MenuItem>
                <MenuItem value="143">GFI Software Development Ltd</MenuItem>
                <MenuItem value="140">GFI Software GmbH</MenuItem>
                <MenuItem value="142">GFI Software IP S.a.r.l.</MenuItem>
                <MenuItem value="138">GFI Software Ltd (UK)</MenuItem>
                <MenuItem value="298">GFI Software Ltd (UK) (Elimination)</MenuItem>
                <MenuItem value="146">GFI Software Pty Ltd</MenuItem>
                <MenuItem value="299">GFI Software Pty Ltd (Elimination)</MenuItem>
                <MenuItem value="141">GFI Software Sales Ltd</MenuItem>
                <MenuItem value="144">GFI USA (Simplified)</MenuItem>
                <MenuItem value="400">GFI USA, LLC</MenuItem>
                <MenuItem value="445">GT.school 78626, LLC</MenuItem>
                <MenuItem value="214">GY Agemni, LLC</MenuItem>
                <MenuItem value="130">Gee FI Holdings Limited</MenuItem>
                <MenuItem value="174">Gee FI Holdings Limited (Elimination)</MenuItem>
                <MenuItem value="447">Gen Alpha Ventures, LLC</MenuItem>
                <MenuItem value="35">Gensym Cayman LP</MenuItem>
                <MenuItem value="305">Gensym Cayman LP (Elimination)</MenuItem>
                <MenuItem value="34">Gensym Corporation</MenuItem>
                <MenuItem value="303">Gensym Corporation (Elimination)</MenuItem>
                <MenuItem value="246">Gensym International Corporation (DE)</MenuItem>
                <MenuItem value="304">Gensym International Corporation (Elimination)</MenuItem>
                <MenuItem value="36">Gensym-BV</MenuItem>
                <MenuItem value="73">Geovue, Inc.</MenuItem>
                <MenuItem value="421">Gigster, LLC</MenuItem>
                <MenuItem value="118">IGNITE CHUTE SOLUTIONS, INC</MenuItem>
                <MenuItem value="191">IMI Holdings Ireland Limited</MenuItem>
                <MenuItem value="321">IMI Holdings Ireland Limited (USD)</MenuItem>
                <MenuItem value="77">Ignite Analytics, LLC</MenuItem>
                <MenuItem value="27">Ignite Auto-Trol Solutions, LLC</MenuItem>
                <MenuItem value="253">Ignite Data Solutions Inc. (Elimination)</MenuItem>
                <MenuItem value="49">Ignite EPM Live Solutions, Inc</MenuItem>
                <MenuItem value="271">Ignite Enterprise Software Solutions, Inc. (Elimination)</MenuItem>
                <MenuItem value="78">Ignite Enterprise Software Solutions, LLC</MenuItem>
                <MenuItem value="349">Ignite Enterprise Solutions, LLC</MenuItem>
                <MenuItem value="350">Ignite Enterprise Solutions, LLC (Elimination)</MenuItem>
                <MenuItem value="38">Ignite Everest Solutions, Inc.</MenuItem>
                <MenuItem value="70">Ignite Firm58 Solutions, LLC</MenuItem>
                <MenuItem value="72">Ignite FirstRain Solutions, Inc.</MenuItem>
                <MenuItem value="8">Ignite Infer Solutions,Inc</MenuItem>
                <MenuItem value="20">Ignite Knowledge Marketing Solutions, Inc.</MenuItem>
                <MenuItem value="24">Ignite Marketing Analytics, Inc.</MenuItem>
                <MenuItem value="317">Ignite Olive Software Solutions, Inc. (Elimination)</MenuItem>
                <MenuItem value="114">Ignite Olive Software Solutions, LLC</MenuItem>
                <MenuItem value="53">Ignite Prologic Solutions Limited</MenuItem>
                <MenuItem value="68">Ignite RMSA Retail Solutions, Inc.</MenuItem>
                <MenuItem value="108">Ignite ResponseTek Solutions,Inc.</MenuItem>
                <MenuItem value="278">Ignite ResponseTek Solutions,Inc. (Elimination)</MenuItem>
                <MenuItem value="85">Ignite SV Solutions, Inc.</MenuItem>
                <MenuItem value="39">Ignite Scalearc Solutions Inc</MenuItem>
                <MenuItem value="276">Ignite Synoptos Solutions, Inc. (Elimination)</MenuItem>
                <MenuItem value="232">Ignite Synoptos Solutions, LLC</MenuItem>
                <MenuItem value="7">Ignite Technologies, Inc.</MenuItem>
                <MenuItem value="405">IgniteTech AnswerHub Solutions, LLC</MenuItem>
                <MenuItem value="398">IgniteTech CX Solutions, LLC</MenuItem>
                <MenuItem value="407">IgniteTech Cosi Solutions, Inc.</MenuItem>
                <MenuItem value="102">IgniteTech Fogbugz Solutions, LLC</MenuItem>
                <MenuItem value="190">Industri-Matematik International AB</MenuItem>
                <MenuItem value="239">Infinio Systems, Inc.</MenuItem>
                <MenuItem value="234">Infoition New Services, Inc.</MenuItem>
                <MenuItem value="205">Interact LLC</MenuItem>
                <MenuItem value="378">Interleaf GmbH</MenuItem>
                <MenuItem value="364">Interleaf UK Ltd</MenuItem>
                <MenuItem value="406">Jigsaw Software, LLC</MenuItem>
                <MenuItem value="159">Jive Australia (Pty) Ltd</MenuItem>
                <MenuItem value="166">Jive Israel Ltd.</MenuItem>
                <MenuItem value="164">Jive Software France Sarl</MenuItem>
                <MenuItem value="322">Jive Software Holdings, LLC</MenuItem>
                <MenuItem value="348">Jive Software Holdings, LLC (Elimination)</MenuItem>
                <MenuItem value="163">Jive Software Limited</MenuItem>
                <MenuItem value="275">Jive Software Limited (Elimination)</MenuItem>
                <MenuItem value="165">Jive Software Limited Zweigniederlassung Deutschland</MenuItem>
                <MenuItem value="215">Jive Software, Inc. (Elimination)</MenuItem>
                <MenuItem value="158">Jive Software, LLC</MenuItem>
                <MenuItem value="425">Kaxxa CF Limited</MenuItem>
                <MenuItem value="19">Kaxxa Holdings, Inc.</MenuItem>
                <MenuItem value="71">Kaxxa Technologies FZ-LLC</MenuItem>
                <MenuItem value="64">Kayako Limited</MenuItem>
                <MenuItem value="63">Kayako Singapore Pte. Ltd.</MenuItem>
                <MenuItem value="277">Kayako Singapore Pte. Ltd. (Elimination)</MenuItem>
                <MenuItem value="148">Kerio Technologies AU Pty Ltd.</MenuItem>
                <MenuItem value="213">Kerio Technologies Malta Ltd</MenuItem>
                <MenuItem value="318">Kerio Technologies Malta Ltd (Elimination)</MenuItem>
                <MenuItem value="139">Kerio Technologies UK Ltd</MenuItem>
                <MenuItem value="129">Kerio Technologies s.r.o.</MenuItem>
                <MenuItem value="128">Kerio Technologies, LLC</MenuItem>
                <MenuItem value="172">Kerio Technologies, LLC (Elimination)</MenuItem>
                <MenuItem value="124">LSSI Europe Limited</MenuItem>
                <MenuItem value="79">Latis Networks Inc.</MenuItem>
                <MenuItem value="414">Learn & Earn LLC</MenuItem>
                <MenuItem value="410">Legacy of Education HoldCo, LLC</MenuItem>
                <MenuItem value="56">Legacy of Education, Inc.</MenuItem>
                <MenuItem value="442">Legends Lab, Inc. - OLD</MenuItem>
                <MenuItem value="371">Lever4 Inc</MenuItem>
                <MenuItem value="403">Liveworksheets Holdings, Inc.</MenuItem>
                <MenuItem value="44">Lyris Investment Corp.</MenuItem>
                <MenuItem value="42">Lyris, Inc.</MenuItem>
                <MenuItem value="260">Lyris, Inc. (Elimination)</MenuItem>
                <MenuItem value="107">MBI Solutions LLC</MenuItem>
                <MenuItem value="84">MessageOne, LLC</MenuItem>
                <MenuItem value="104">Mobilogy Inc.</MenuItem>
                <MenuItem value="117">MyAlerts, LLC</MenuItem>
                <MenuItem value="244">NZ Branch of S.L.I. Systems, Inc. -Old</MenuItem>
                <MenuItem value="326">NetPlus Buyer, Inc. (DE)</MenuItem>
                <MenuItem value="328">NetPlus Buyer, Inc. (DE) (Elimination)</MenuItem>
                <MenuItem value="324">NetPlus Group, LLC (DE)</MenuItem>
                <MenuItem value="325">NetPlus Group, LLC (DE) (Elimination)</MenuItem>
                <MenuItem value="329">Netplus Mobility, LLC (DE)</MenuItem>
                <MenuItem value="204">NewNet Communication Technologies BV</MenuItem>
                <MenuItem value="94">NextDocs Corporation</MenuItem>
                <MenuItem value="408">Nimzo-I Technologies Private Limited</MenuItem>
                <MenuItem value="388">NinePoint Medical, Inc.</MenuItem>
                <MenuItem value="208">North Plains (UK) Limited</MenuItem>
                <MenuItem value="294">North Plains (UK) Limited (Elimination)</MenuItem>
                <MenuItem value="207">North Plains Holdings (US), Inc.</MenuItem>
                <MenuItem value="293">North Plains Holdings (US), Inc. (Elimination)</MenuItem>
                <MenuItem value="206">North Plains Systems Corp.</MenuItem>
                <MenuItem value="210">North Plains, LLC</MenuItem>
                <MenuItem value="76">NuView Systems, LLC</MenuItem>
                <MenuItem value="431">Oasis Software Holdings Limited</MenuItem>
                <MenuItem value="432">Oasis Software Malta Limited</MenuItem>
                <MenuItem value="69">ObjectStore, Inc.</MenuItem>
                <MenuItem value="233">OhMyGov, Inc.</MenuItem>
                <MenuItem value="115">Olive Software Limited</MenuItem>
                <MenuItem value="331">Optimizer Streetco Inc. (DE) (Elimination)</MenuItem>
                <MenuItem value="330">Optimizer Streetco, LLC</MenuItem>
                <MenuItem value="237">Peer-App Research And Development Ltd. (Israel)</MenuItem>
                <MenuItem value="216">PeerApp (US), Inc.</MenuItem>
                <MenuItem value="195">Pivotal Corporation Ireland Unlimited</MenuItem>
                <MenuItem value="290">Pivotal Corporation Ireland Unlimited (Elimination)</MenuItem>
                <MenuItem value="196">Pivotal Technologies Corporation Limited</MenuItem>
                <MenuItem value="401">Posh Wich Technology, LLC</MenuItem>
                <MenuItem value="402">Project Atacama LLC</MenuItem>
                <MenuItem value="389">Prysm, Inc.</MenuItem>
                <MenuItem value="284">Quantum Retail Technology, Inc. (Elimination)</MenuItem>
                <MenuItem value="80">Quantum Retail Technology, LLC</MenuItem>
                <MenuItem value="81">Ravenflow, Inc.</MenuItem>
                <MenuItem value="109">ResponseTek (Canada) Inc.</MenuItem>
                <MenuItem value="279">ResponseTek (Canada) Inc. (Elimination)</MenuItem>
                <MenuItem value="110">ResponseTek Australia Branch</MenuItem>
                <MenuItem value="111">ResponseTek India Private Limited</MenuItem>
                <MenuItem value="112">ResponseTek United Kingdom Branch</MenuItem>
                <MenuItem value="87">Right90, Inc.</MenuItem>
                <MenuItem value="310">Rose ASP.com</MenuItem>
                <MenuItem value="396">S.L.I Systems, Inc.</MenuItem>
                <MenuItem value="297">S.L.I Systems, Inc. (Elimination) -Old</MenuItem>
                <MenuItem value="354">S.L.I Systems, Inc. -Do not use</MenuItem>
                <MenuItem value="243">S.L.I Systems, Inc. -Old</MenuItem>
                <MenuItem value="357">S.L.I Systems, Inc. NZD -Old</MenuItem>
                <MenuItem value="219">SLI Systems (Japan) K.K -Old</MenuItem>
                <MenuItem value="355">SLI Systems (UK) Limited</MenuItem>
                <MenuItem value="217">SLI Systems (UK) Limited -Old</MenuItem>
                <MenuItem value="352">SLI Systems Limited</MenuItem>
                <MenuItem value="392">SLI Systems Limited (NZ) (Elimination)</MenuItem>
                <MenuItem value="296">SLI Systems Limited (NZ) (Elimination)-Old</MenuItem>
                <MenuItem value="240">SLI Systems Limited (NZ) -Old</MenuItem>
                <MenuItem value="285">Saphire AC Holdings, Inc. (Elimination)</MenuItem>
                <MenuItem value="178">Sapphire AC Holdings LLC</MenuItem>
                <MenuItem value="185">Sapphire France S.A.S.</MenuItem>
                <MenuItem value="182">Saratoga Systems, AB</MenuItem>
                <MenuItem value="198">School Loop, LLC</MenuItem>
                <MenuItem value="382">Security First Corp</MenuItem>
                <MenuItem value="413">Skyvera Communications, LLC</MenuItem>
                <MenuItem value="86">Skyvera, LLC</MenuItem>
                <MenuItem value="320">Sococo, Inc.</MenuItem>
                <MenuItem value="319">Sococo, LLC</MenuItem>
                <MenuItem value="418">Spearhead Corporation Limited</MenuItem>
                <MenuItem value="419">Spearhead Intermediate Holdings Limited</MenuItem>
                <MenuItem value="95">Spiral SVS SAS</MenuItem>
                <MenuItem value="444">Sports Academy 75010, LLC</MenuItem>
                <MenuItem value="443">Sports Academy 78734, LLC</MenuItem>
                <MenuItem value="424">Stratifyd Software, LLC</MenuItem>
                <MenuItem value="31">Swiftcurrent Holdings Inc</MenuItem>
                <MenuItem value="263">Swiftcurrent Holdings Inc (Elimination)</MenuItem>
                <MenuItem value="106">Symphony Commerce, LLC</MenuItem>
                <MenuItem value="236">Synoptos EOOD (Bulgaria)</MenuItem>
                <MenuItem value="387">Tall Marmot LLC</MenuItem>
                <MenuItem value="145">Techgenix Limited (Malta)</MenuItem>
                <MenuItem value="9">The Group</MenuItem>
                <MenuItem value="314">The Group (Elimination)</MenuItem>
                <MenuItem value="248">Think3 KK (Japan)</MenuItem>
                <MenuItem value="312">Think3 WOFE (China)</MenuItem>
                <MenuItem value="88">Think3, Inc.</MenuItem>
                <MenuItem value="261">Think3, Inc. (Elimination)</MenuItem>
                <MenuItem value="54">Tires Made Easy, Inc.</MenuItem>
                <MenuItem value="438">Tivian AS</MenuItem>
                <MenuItem value="439">Tivian Inc</MenuItem>
                <MenuItem value="441">Tivian Ltd</MenuItem>
                <MenuItem value="440">Tivian XI Gmbh</MenuItem>
                <MenuItem value="83">TriActive, Inc.</MenuItem>
                <MenuItem value="48">Trilogy E-Business Software India Private Ltd</MenuItem>
                <MenuItem value="45">Trilogy Enterprises, Inc. (DE)</MenuItem>
                <MenuItem value="302">Trilogy Enterprises, Inc. (Elimination)</MenuItem>
                <MenuItem value="46">Trilogy International Operations, Inc</MenuItem>
                <MenuItem value="313">Trilogy International Operations, Inc (Elimination)</MenuItem>
                <MenuItem value="353">Trilogy Japan K.K.</MenuItem>
                <MenuItem value="32">Trilogy, Inc. (DE)</MenuItem>
                <MenuItem value="167">Trilogy, Inc. (Elimination)</MenuItem>
                <MenuItem value="99">Update CRM Inc.</MenuItem>
                <MenuItem value="97">Update Software Benelux B.V.</MenuItem>
                <MenuItem value="100">Update Software Deutschland GmbH</MenuItem>
                <MenuItem value="258">Vasona AC, Inc. (Elimination)</MenuItem>
                <MenuItem value="223">Vasona AC, Inc. (fka TargetCo AC, Inc.)</MenuItem>
                <MenuItem value="266">Vasona Intermediate Holdings, Inc. (Elimination)</MenuItem>
                <MenuItem value="228">Vasona Intermediate Holdings, Inc. (fka MiddleCo, Inc.)</MenuItem>
                <MenuItem value="154">Vasona Networks Inc</MenuItem>
                <MenuItem value="156">Vasona Networks International Holdings Inc</MenuItem>
                <MenuItem value="155">Vasona Networks Ltd</MenuItem>
                <MenuItem value="157">Vasona Networks Mexico S. de R.L. de C.V.</MenuItem>
                <MenuItem value="267">Vasona Networks, Inc. (Elimination)</MenuItem>
                <MenuItem value="327">Ventraq Corporation (MD)</MenuItem>
                <MenuItem value="247">Versata (India) Private limited</MenuItem>
                <MenuItem value="43">Versata Development Group, Inc.</MenuItem>
                <MenuItem value="33">Versata Enterprises, Inc</MenuItem>
                <MenuItem value="301">Versata Enterprises, Inc (Elimination)</MenuItem>
                <MenuItem value="52">Versata FZ-LLC</MenuItem>
                <MenuItem value="41">Versata International, Inc.</MenuItem>
                <MenuItem value="308">Versata International, Inc. (Elimination)</MenuItem>
                <MenuItem value="40">Versata Software, Inc</MenuItem>
                <MenuItem value="307">Versata Software, Inc (Elimination)</MenuItem>
                <MenuItem value="66">Versata, Inc</MenuItem>
                <MenuItem value="306">Versata, Inc (Elimination)</MenuItem>
                <MenuItem value="203">Viking Acquisitions Corp.</MenuItem>
                <MenuItem value="356">Virtasant, LLC</MenuItem>
                <MenuItem value="119">Volt Delta Canada Holdings LLC</MenuItem>
                <MenuItem value="282">Volt Delta Canada Holdings LLC (Elimination)</MenuItem>
                <MenuItem value="153">Volt Delta Company</MenuItem>
                <MenuItem value="199">Volt Delta Gmbh</MenuItem>
                <MenuItem value="281">Volt Delta Gmbh (Elimination)</MenuItem>
                <MenuItem value="200">Volt Delta International Gmbh</MenuItem>
                <MenuItem value="123">Volt Delta International Ltd.</MenuItem>
                <MenuItem value="283">Volt Delta International Ltd. (Elimination)</MenuItem>
                <MenuItem value="238">Volt Delta Ireland Branch</MenuItem>
                <MenuItem value="121">Volt Delta Resources, LLC</MenuItem>
                <MenuItem value="280">Volt Delta Resources, LLC (Elimination)</MenuItem>
                <MenuItem value="209">Vyre Limited</MenuItem>
                <MenuItem value="47">Worlwide Trilogy (Mauritius)</MenuItem>
                <MenuItem value="395">XANT UK LIMITED</MenuItem>
                <MenuItem value="394">XANT, LLC dba InsideSales</MenuItem>
                <MenuItem value="311">XRM Live, LLC</MenuItem>
                <MenuItem value="295">Xinet (Elimination)</MenuItem>
                <MenuItem value="212">Xinet GmbH</MenuItem>
                <MenuItem value="211">Xinet, LLC</MenuItem>
                <MenuItem value="332">Xora Software Systems</MenuItem>
                <MenuItem value="347">YYYYY, Inc (Elimination)</MenuItem>
                <MenuItem value="229">YYYYY, LLC</MenuItem>
                <MenuItem value="399">ZZ test</MenuItem>
                <MenuItem value="415">Zax Capital, Inc.</MenuItem>
                <MenuItem value="446">Zax Capital, LLC (Elimination)</MenuItem>
                <MenuItem value="270">ZephyrTel, Inc. (Elimination)</MenuItem>
                <MenuItem value="230">Zeus Jointco Holdco, LLC</MenuItem>
                <MenuItem value="231">Zeus Jointco, LLC</MenuItem>
                <MenuItem value="351">Zumobi, LLC</MenuItem>
                <MenuItem value="67">gTeam US, LLC</MenuItem>
                <MenuItem value="96">update sales GmbH</MenuItem>
                <MenuItem value="101">update software Switzerland GmbH</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Draft Subscription"
              name="draftSubscription"
              value={subTransferFormData.draftSubscription}
              onChange={(e) => setSubTransferFormData({...subTransferFormData, draftSubscription: e.target.value})}
              fullWidth
              margin="normal"
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs={12} sm={6} container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6}>
            <DateField
              label="Transfer Date Field"
              value={subTransferFormData.startDate}
              onChange={(date: any) => setSubTransferFormData({...subTransferFormData, startDate: date})}
              fullWidth
            />
           </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
              label="Memo Field"
              name="memo"
              value={subTransferFormData.memo}
              onChange={(e) => setSubTransferFormData({...subTransferFormData, memo: e.target.value})}
              fullWidth
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent="center" marginTop={"50px"}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={
                !subTransferFormData.subscriptionId || !subTransferFormData.newSubsidiaryId || !subTransferFormData.draftSubscription || isSubmitting
              }
            >
            Submit
            </Button>
          </Grid>
        </Grid>
      </Container>
    </LocalizationProvider>
  );
};

export default SubscriptionTransferForm;

