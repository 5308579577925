import { API_URL } from "src/util/config";
import { checkResponse } from "src/util/helpers";
import {SubscriptionTransferRequestSummary, SubscriptionTransferRequest, SubscriptionTransferProps} from "models/subscription-transfer";

export class SubscriptionTransferRequestService {
  async getRecentSubscriptionTransferRequests(
    limit: number,
    lastEvaluatedKey?: string
  ): Promise<{
    data: SubscriptionTransferRequestSummary[];
    lastEvaluatedKey?: string;
  }> {
    const queryParams = new URLSearchParams({ limit: limit.toString() });
    if (lastEvaluatedKey) {
      queryParams.append("lastEvaluatedKey", lastEvaluatedKey);
    }
    const response = await fetch(
      `${API_URL}/subscription-transfer?${queryParams.toString()}`
    );
    const data = await checkResponse(response);
    return data as {
      data: SubscriptionTransferRequestSummary[];
      lastEvaluatedKey?: string;
    };
  }

  async getSubscriptionTransferRequestById(id: string): Promise<SubscriptionTransferRequest> {
    const response = await fetch(`${API_URL}/subscription-transfer/${id}`);
    const data = await checkResponse(response);
    return data as SubscriptionTransferRequest;
  }

  async createSubscriptionTransferRequest(
    requestData: SubscriptionTransferProps
  ): Promise<SubscriptionTransferRequest> {
    const response = await fetch(`${API_URL}/subscription-transfer`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestData),
    });
    const data = await checkResponse(response);
    return data as SubscriptionTransferRequest;
  }
}
