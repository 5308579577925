import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SubscriptionTransferRequestService } from "../../services/subscriptionTransferService";
import { SubscriptionTransferRequest } from "models/subscription-transfer";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Link,
  Chip,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputDetails from "./display-components/InputDetails";
import { CommonStep } from "./display-components/SubscriptionTransferOutputSteps";
import StatusChip from "../general/StatusChip";

const SubscriptionTransfer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [subTransferRequest, setSubTransferRequest] =
    useState<SubscriptionTransferRequest | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [refreshing, setRefreshing] = useState(false);

  const fetchSubTransferRequest = useCallback(async () => {
    const lookupId = id?.split("-")?.[1];
    if (lookupId) {
      const subscriptionTransferService = new SubscriptionTransferRequestService();
      setRefreshing(true); // Set refreshing to true before fetching data
      const startTime = Date.now(); // Record the start time
      try {
        const subTransferData = await subscriptionTransferService.getSubscriptionTransferRequestById(lookupId);
        console.log("Subscription Transfer Request:", subTransferData);
        setSubTransferRequest(subTransferData);
      } catch (err) {
        setError("Failed to fetch subscription transfer data.");
      }
      const elapsed = Date.now() - startTime;
      const delay = Math.max(0, 2000 - elapsed); // Calculate delay to ensure 2 seconds
      setTimeout(() => {
          setLoading(false);
          setRefreshing(false); // Set refreshing to false after fetching data
        },
       delay
      );
    }
  }, [id]);

  useEffect(() => {
    fetchSubTransferRequest();
  }, [fetchSubTransferRequest]);

  useEffect(() => {
    if (subTransferRequest?.status === "IN_PROGRESS") {
      const intervalId = setInterval(() => {
        fetchSubTransferRequest();
      }, 5000); // Change the interval to 5 seconds
      return () => clearInterval(intervalId);
    }
  }, [subTransferRequest, fetchSubTransferRequest]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  const renderOutputStep = (key: string) => {
    let step = null
    let seq = 0
    let displayId = undefined
    let displayUrl = undefined
    switch (key) {
      case "TurnOfAutoRenewal": {
        step = subTransferRequest!.output.TurnOfAutoRenewal;
        seq = 1;
        break;
      }
      case "SubscriptionLinesAndPriceIntervals": {
        step = subTransferRequest!.output.SubscriptionLinesAndPriceIntervals;
        seq = 2;
        break;
      }
      case "TerminateSubsciption": {
        step = subTransferRequest!.output.TerminateSubsciption;
        seq = 3;
        displayId = subTransferRequest!.output.TerminateSubsciption.subscriptionId ?
          `Subscription ID: ${subTransferRequest!.output.TerminateSubsciption.subscriptionId}` : undefined;
        displayUrl = `/accounting/subscription/subscription.nl?id=${subTransferRequest!.output.TerminateSubsciption.subscriptionId}`
        break;
      }
      case "ChangeCustomerPreferences": {
        step = subTransferRequest!.output.ChangeCustomerPreferences;
        seq = 4;
        break;
      }
      case "CreateCreditMemo": {
        step = subTransferRequest!.output.CreateCreditMemo;
        seq = 5;
        displayId =  subTransferRequest!.output.CreateCreditMemo.creditMemoId ? 
            `Credit Memo ID: ${subTransferRequest!.output.CreateCreditMemo.creditMemoId}` : undefined;
        displayUrl = `/accounting/transactions/custcred.nl?id=${subTransferRequest!.output.CreateCreditMemo.creditMemoId}`;
        break;
      }
      case "RenameCreditMemoToInternalOnly": {
        step = subTransferRequest!.output.RenameCreditMemoToInternalOnly;
        seq = 6;
        break;
      }
      case "RevertPreferences": {
        step = subTransferRequest!.output.RevertPreferences;
        seq = 7;
        break;
      }
      case "CreateNewSubsidiaryWithinCustomer": {
        step = subTransferRequest!.output.CreateNewSubsidiaryWithinCustomer;
        seq = 8;
        break;
      }
      case "CreateBillingAccount": {
        step = subTransferRequest!.output.CreateBillingAccount;
        seq = 9;
        displayId = subTransferRequest!.output.CreateBillingAccount.billingAccountId ? 
            `Billing Account ID: ${subTransferRequest!.output.CreateBillingAccount.billingAccountId}` : undefined;
        displayUrl = `/accounting/otherlists/billingaccount.nl?id=${subTransferRequest!.output.CreateBillingAccount.billingAccountId}`;
        break;
      }
      case "CreateNewSubscription": {
        step = subTransferRequest!.output.CreateNewSubscription;
        seq = 10;
        displayId = subTransferRequest!.output.CreateNewSubscription.newSubscriptionId ? 
            `New Subscription ID: ${subTransferRequest!.output.CreateNewSubscription.newSubscriptionId}` : undefined;
        displayUrl = `/accounting/subscription/subscription.nl?id=${subTransferRequest!.output.CreateNewSubscription.newSubscriptionId}`;
        break;
      }
      case "SyncBothSubscriptions": {
        step = subTransferRequest!.output.SyncBothSubscriptions;
        seq = 11;
        break;
      }
      case "CreateNewInvoice": {
        step = subTransferRequest!.output.CreateNewInvoice;
        seq = 12;
        displayId = subTransferRequest!.output.CreateNewInvoice.invoiceId ? 
            `Invoice ID: ${subTransferRequest!.output.CreateNewInvoice.invoiceId}` : undefined;
        displayUrl = `/accounting/transactions/custinvc.nl?id=${subTransferRequest!.output.CreateNewInvoice.invoiceId}`;
        break;
      }
      case "CreateJournalEntry": {
        step = subTransferRequest!.output.CreateJournalEntry;
        seq = 13;
        displayId = subTransferRequest!.output.CreateJournalEntry.journalId ?
             `Journal ID: ${subTransferRequest!.output.CreateJournalEntry.journalId}` : undefined;
        displayUrl = `/accounting/transactions/icjournal.nl?id=${subTransferRequest!.output.CreateJournalEntry.journalId}`;
        break;
      }
      case "DeleteDraftSubscription": {
        step = subTransferRequest!.output.DeleteDraftSubscription;
        seq = 14;
        break;
      }
      default:
        return <></>;
    }
    return (
      <CommonStep
        name={step.name}
        status={step.status}
        step={seq}
        displayId={displayId}
        displayUrl={displayUrl}
      />
    );
  };

  return (
    <Box p={3}>
      {subTransferRequest ? (
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box display="flex" alignItems="center" mb={3} gap={2}>
            <IconButton onClick={() => navigate("/subscription-transfers")} size="small">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4">
              SUBTRANSFER-{subTransferRequest?.requestId}
            </Typography>
            <Chip label={subTransferRequest?.requester} color="default" />
            <StatusChip status={subTransferRequest.status} />
            {refreshing && <CircularProgress size={24} />}
          </Box>
          {subTransferRequest.transferId && (
            <Typography variant="body1" mb={3}>
              Transfer ID:{" "}
              <Link
                href= "/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {subTransferRequest.transferId}
              </Link>
            </Typography>
          )}
          <Box>
            <Typography variant="h6">Output Details</Typography>
            <Box display="flex" flexWrap="wrap">
              {Object.keys(subTransferRequest.output).map((key) => (
                <Box key={key} width="280px" p={1}>
                  {renderOutputStep(key)}
                </Box>
              ))}
            </Box>
          </Box>
          {subTransferRequest.status === "FAILED" && (
            <Box
              mb={1}
              p={2}
              borderRadius={2}
              border={1}
              borderColor="error.main"
              bgcolor="#ffdddd"
              color="error.main"
            >
              <Typography variant="body2" sx={{ paddingBottom: "4px" }}>
                <strong>Uh oh!</strong> Sorry that this subscription transfer request has
                failed.
              </Typography>
            </Box>
          )}
          <InputDetails input={subTransferRequest.input} />
        </Paper>
      ) : (
        <Typography variant="body1">No subscription transfer requests found.</Typography>
      )}
    </Box>
  );
};

export default SubscriptionTransfer;
